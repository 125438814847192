@import "colors";

.btn {
  border-radius: 50px;
}

.btn-submit {
  background-color: $color-apple;
  color: white;
  &:hover {
    color: white;
  }
}

.btn-search {
  background-color: white;
}
